@charset 'utf-8'

//bright green
$primary: #0AF77C
//dark blue
$info: #2870d4
// footer
$dark: #404040
// index background gray
$light: #E8E8E8

$warning: #82D9FE

$light-gray: #C6C6C6
//
  blue: #2870d4
  light gray: #C6C6C6
  dark gray: #3B3B3B
  darker gray: #2A2A2A
  bright green: #0AF77C

// IMPORT FONTS LIKE THIS:

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,500;0,600;0,700;0,800;1,500&display=swap')
// TELL BULMA TO USE FONTS LIKE THIS (the rest of the list are Bulma's default fonts):
$family-sans-serif: 'Montserrat', BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif

//ALL STYLING
body, html
  margin: 0
  height: 100%

body
  display: flex
  flex-direction: column

.content
  flex: 1 0 auto
  background-color: $light

.footer
  flex-shrink: 0

.foot-c
  width: 90%
  margin: auto

.footer-link
  border: 2px $light solid
  border-radius: 10px
  display: inline-block
  padding: .5rem
  margin: 1rem auto

a.footer-link
  color: $light

.footer-link:hover
  transition: .3s
  background-color: $light
  color: $dark

.columns.my-0
  margin: 0

.webcontainer
  display: table
  margin: 0

//NAV/HEADER STYLING
.name
  margin: auto

.columns.is-mobile.fullheader
  position: auto
  background-color: #fff
  margin: 0

//open and closing animations in responsive styling section

.column.navslider.conditionalClose.hideMe
  display: none

.column.navslider
  background-color: #FBFBFB

@keyframes slideOpen
  from
    margin-left: -25%
    padding-right: 0
    padding-left: 0
  to
    margin-left: 0px

@keyframes slideClosed
  from
    margin-left: 0px
  to
    margin-left: -25%
    padding-right: 0
    padding-left: 0

@keyframes topOpen
  from
    height: 0%
  to
    height: 100%

@keyframes topClose
  from
    height: 100%
  to
    height: 0%

i.flip-burger
  color: $light-gray
  padding-top: 1rem
  padding-left: 1rem


//each link things
.box.navlinks
  margin-left: .2rem
  margin-right: .2rem

.box.navlinks a
  color: #3B3B3B

.box.navlinks i
  color: white
  background-color: $light-gray
  padding: 15px
  border-radius: 100px

.has-text-gray
  color: #525252

.box.navlinks.is-active
  background-color: $info

.box.navlinks.is-active a, .box.is-active .has-text-gray
  color: white

.box.navlinks.is-active i
  color: $light-gray
  background-color: white

sup, a
  color: #0025ba

//onhover
.box.navlinks:hover
  background-color: #b8b8b8
  transition: .3s

.box.navlinks:hover a, .box:hover .has-text-gray
  color: white
  transition: .3s

.box.navlinks:hover i
  color: $light-gray
  background-color: white
  transition: .3s

$box-shadow: 0 0.5em 1em -0.125em rgb(131 131 131 / 10%), 0 0px 0 1px rgba(10, 10, 10, 0.02)
$box-radius: 0px

.is-size-small
  font-size: .8rem

.box.navlinks .column  p
  margin-bottom: 0

// home page styling
//carousel :-)
.tweet
  background-color: #4F4F4F
  padding: .7rem 1rem
  margin: 1rem .2rem
  border-radius: 20px


.tweet p
  color: #E1E1E1

.carousel-container
  overflow: hidden

.owl-carousel#myCarousel
  width: 700px
  margin: auto

.owl-stage
  display: flex
  align-items: center

//rest of Home
.page-container
  margin: auto
  padding-left: 5rem
  padding-right: 5rem

.content .bubble-c .learnmore-btn
  color: white
  background-color: $primary
  text-align: center
  padding: .5rem 1rem
  font-weight: bold
  font-size: 1.5rem
  border-radius: .7rem
  margin-bottom: 0

.bubble-c
  width: 200px
  margin: auto
  display: block

.triangle
  width: 40px
  margin: 0 130px


//footer
footer
  color: #939393

// LEARNING page
// mini nav
.l-links-container
  display: flex
  align-items: stretch

.l-links-container .link
  background-color: #D2d2d2
  padding: .4rem 1rem
  border-radius: .5rem
  font-weight: bold
  font-size: 18px
  margin: 0 .5rem
  flex: 1
  text-align: center

.l-links-container a
  color: white
  font-weight: bold
  font-size: 18px

.l-links-container .link:hover, .l-links-container a:hover
  background-color: $light-gray
  color: white

.l-links-container .link.is-current-point, .is-current-point a:hover
  background-color: $primary

img.right-image, .right-image
  float: right
  width: 50%

.column.next-button
  align-self: flex-end

.next-button a
  float: right
  background-color: $primary
  color: white
  padding: .4rem 1rem
  border-radius: .5rem
  font-weight: bold
  font-size: 18px
  text-align: center

.next-button a:hover
  color: white

.column.prev-button
  align-self: flex-start

.prev-button a
  float: left
  background-color: $primary
  color: white
  padding: .4rem 1rem
  border-radius: .5rem
  font-weight: bold
  font-size: 18px
  text-align: center

.prev-button a:hover
  color: white


.response ol
  list-style-position: inside

.column .rectangle
  background-color: #f4f4f4
  border: solid 3px #DDD

.rectangle
  padding: 1rem

.rectangle p, .rectangle li
  font-size: 14px
  padding-top: .6rem

.video
  float: right
  width: 45%
  border-radius: 10px
  margin: .75rem 1rem

.video:focus
  outline: 0

.list
  list-style-type: circle
  list-style-position: inside

// resources
.resource-buttons, .resource-buttons a
  display: table

.resource-buttons a
  color: white
  background-color: $info
  padding: 1rem
  border-radius: 10px
  font-size: 18px
  text-align: center
  margin: 1rem 0

.resource-buttons a:hover
  color: white
  background-color: #b8b8b8
  transition: .3s

a.green:hover
  background-color: $primary

//About
.me
  max-width: 400px
  display: flex
  align-items: flex-start
  flex-direction: column

// bibliography
.sources ol li
  word-break: break-word

//quiz / react section
.quiz-page
  padding-top: 3rem

.start
  width: 40%
  margin: auto
  padding: 3rem 1rem
  font-size: 3rem

.show
  animation: fadeIn normal forwards ease-in-out
  animation-duration: .5s
  display: block

.hide
  display: none

.quiz-element
  cursor: pointer

.question
  padding: .5rem 5rem
  font-size: 1.5rem

.answers
  list-style-type: none
  padding: 1rem 5rem

.answers li
  border: $dark solid
  border-width: .3rem
  padding: 1rem
  margin: 2%
  width: 46%
  display: inline-table

.results-title
  padding: 2rem 5rem
  font-weight: bold

.results-spiel
  padding: 1rem 5rem
  text-align: center

.results-spiel p
  margin-bottom: 0

.retry-button
  padding: 1rem
  margin-top: 1rem
  font-size: 24px
  display: inline-block

.timer-c
  border: solid white 3px
  width: 4rem
  text-align: center
  height: 4rem
  display: flex
  align-items: center

.correct-answer
  background-color: $primary

.wrong-answer
  background-color: #ff4a4a
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both
  transform: translate3d(0, 0, 0)
  backface-visibility: hidden

@keyframes shake
  10%, 90%
    transform: translate3d(-1px, 0, 0)
  20%, 80%
    transform: translate3d(2px, 0, 0)
  30%, 50%, 70%
    transform: translate3d(-4px, 0, 0)
  40%, 60%
    transform: translate3d(4px, 0, 0)



@keyframes fadeIn
  from
    opacity: 0
  to
    opacity: 1

@keyframes fadeOut
  from
    opacity: 1
  to
    opacity: 0


// USE BULMA~THIS:
@import '~bulma/sass/utilities/_all.sass'

@import '~bulma/sass/base/_all.sass'

@import '~bulma/sass/elements/box.sass'
@import '~bulma/sass/elements/content.sass'

@import '~bulma/sass/helpers/color.sass'
@import '~bulma/sass/helpers/flexbox.sass'
@import '~bulma/sass/helpers/spacing.sass'
@import '~bulma/sass/helpers/typography.sass'
@import '~bulma/sass/helpers/visibility.sass'

@import '~bulma/sass/elements/image.sass'
@import '~bulma/sass/elements/title.sass'

@import '~bulma/sass/grid/_all.sass'

// @import '~bulma/sass/components/navbar.sass'
// @import '~bulma/sass/components/dropdown.sass'
// @import '~bulma/sass/components/media.sass'

@import '~bulma/sass/layout/_all.sass'


// USE BULMA AS A WHOLE LIKE THIS (before production, switch to modular to reduce size)
// @import '~bulma/bulma.sass'

//breakpoints
$very-tiny: 199px
$small: 200px
$small-max: 399px
$between: 400px
$between-max: 599px
$medium: 600px
$medium-max: 768px
$tab: 769px
$tab-max: 1139px
$desk: 1140px
$desk-max: 1279px
$large: 1280px
$large-max: 1599px
$xl: 1600px

// AFTER BULMA IS IMPORTED, USE BULMA VARIABLES LIKE THIS:
@media only screen and (max-width: $very-tiny)
@media only screen and (min-width: $small)

@media only screen and (max-width: $small-max)
  .column.is-1.burger-container.is-flex.is-align-content-center
    width: 20%
    float: left
  .name
    margin-left: 0
  //babyer carousel
  .small.owl-carousel#myCarousel
    width: 250px
    margin: auto
  //quiz
  .answers
    padding: 1rem 2rem
  .question
    font-size: 1rem
  .timer-c
    font-size: 1.5rem
@media only screen and (min-width: $between)
  .question
    font-size: 1.25rem
  .answers
    padding: 1rem 4rem
@media only screen and (max-width: $between-max)
  .right-image, img.right-image, .video
    float: none
    width: 90%
    margin: .75rem auto
    display: flex
    justify-content: center
@media only screen and (min-width: $medium)
  .name
    text-align: center
@media only screen and (max-width: $medium-max)
  .is-size-3.pt-3.pl-4.flip-burger.has-text-centered
    padding-top: 0
  .column.is-6.is-offset-2.is-centered.cond-pat
    width: 80%
    margin: 0
    padding: 0 0 0
  a.py-3.is-flex.is-flex-wrap-nowrap.is-justify-content-center
    padding-left: 0
  i.flip-burger
    color: $light-gray
    padding-top: .5rem
    padding-left: 0
    font-size: 15px
  .desktop-only
    display: none
  .not-mobile
    display: none
  // navigation
  .conditionalOpen
    animation: none
  .conditionalClose
    animation: none
  .navslider
    position: relative
    z-index: 0
  .column.navslider
    background-color: #fff
    color: #fff
  .fullheader
    position: relative
    z-index: 2
    background-color: #fff
  .box.navlinks
    padding: 0 1rem
    box-shadow: 0 0 0 0
  .box.navlinks.is-active
    background-color: #fff
  .box.navlinks.is-active a, .box.is-active .has-text-gray
    color: #3B3B3B
  .spiel
    display: none
  .nav-text
    text-align: center
  //baby carousel
  .owl-carousel#myCarousel
    width: 400px
    margin: auto
  .page-container
    padding: 0 2rem
  //quiz
  .start
    width: 60%
    margin: auto
    padding: 1rem 1rem
  .answers li
    border: $dark solid
    border-width: .3rem
    padding: 1rem
    margin: .2rem auto
    width: 100%
    display: inline-table
  .question
    padding: .5rem 1rem
  .results, .results-spiel, .results-title
    padding: 1rem
  .resource-buttons, .resource-buttons a
    margin: 1rem auto


@media only screen and (min-width: $tab)
  .spiel
    display: auto
  .desktop-only
    display: none
  .conditionalOpen
    animation: slideOpen normal forwards ease-in-out
    animation-duration: .7s
    overflow: hidden
  .conditionalClose
    animation: slideClosed normal forwards ease-in-out
    animation-duration: .7s
    overflow: hidden
  .fullheader
    position: auto
    background-color: #fff
  .box.navlinks.is-active
    background-color: $info
  .box.navlinks.is-active a, .box.is-active .has-text-gray
    color: white

  @media only screen and (min-width: $desk)
    .desktop-only
      display: flex
@media only screen and (max-width: $tab-max)
@media only screen and (min-width: $desk)
@media only screen and (max-width: $desk-max)
@media only screen and (min-width: $large)
@media only screen and (max-width: $large-max)
@media only screen and (min-width: $xl)
